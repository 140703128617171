<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="HAZARD_CLASS_FST_CD"
            type="search"
            :disabled="true"
            itemText="codeName"
            itemValue="code"
            name="hazardClassFstCd"
            label="유해인자 대분류"
            v-model="searchParam.hazardClassFstCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :comboItems="occSecItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="hazardClassSecCd"
            label="유해인자 중분류"
            v-model="searchParam.hazardClassSecCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="유해인자 목록"
          tableId="guideBook"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="hazardCd"
          :columnSetting="false"
          :isExcelDown="false"
          :isExpan="false"
          :isFullScreen="false"
          :selection="popupParam.type"
          :expandAll="true"
          @rowDblclick="rowDblclick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
              <c-btn label="선택" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'harmful-factors-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          hazardClassFstCd: '',
          workEnvFlag: 'Y',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'hazardClassFstName',
            field: 'hazardClassFstName',
            label: '유해인자 대분류',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'hazardClassSecName',
            field: 'hazardClassSecName',
            label: '유해인자 중분류',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'hazardCd',
            field: 'hazardCd',
            label: '유해인자코드',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자명',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      occSecItems: [],
      searchParam: {
        hazardClassFstCd: null,
        hazardClassSecCd: null,
        useFlag: 'Y',
        workEnvFlag: null,
      },
      period: [ '', ''],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.hazard.list.url;

      if (this.popupParam.hazardClassFstCd) {
        this.setSecondItems(this.popupParam.hazardClassFstCd);
        this.searchParam.hazardClassFstCd = this.popupParam.hazardClassFstCd
      }
      if (this.popupParam.workEnvFlag) {
        this.searchParam.workEnvFlag = this.popupParam.workEnvFlag;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPopup(row);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '유해인자를 선택하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
    datachange() {
      this.setSecondItems(this.popupParam.hazardClassFstCd)
    },
    setSecondItems(hazardClassFstCd) {
      this.$http.url = this.$format(selectConfig.sys.code.mst.list.attr.url, 'HAZARD_CLASS_SEC_CD', hazardClassFstCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.occSecItems = [];
        this.searchParam.hazardClassSecCd = null;
        this.$_.forEach(_result.data, _item => {
          this.occSecItems.splice(0, 0, {
            code: _item.code,
            codeName: _item.codeName,
          })
        })
      },);
    },
  }
};
</script>
